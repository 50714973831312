import React, { useEffect, useState, useMemo, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";

const Home = React.lazy(() => import("./components/Home/Home"));
const ContactUs = React.lazy(() => import("./components/ContactUs/ContactUs"));
const About_Us = React.lazy(() => import("./components/About_Us/About_Us"));
const ShopeLocation = React.lazy(() => import("./components/ShopeLocation/ShopeLocation"));
const Home_Gallery = React.lazy(() => import("./components/Home_Gallery/Home_Gallery"));
const CategoryDetails = React.lazy(() => import("./components/CategoryDetails/CategoryDetails"));
const Home_Blog = React.lazy(() => import("./components/Home_Blog/Home_Blog"));
const Chatbox = React.lazy(() => import("./components/Chatbox/Chatbox"));
const Login = React.lazy(() => import("./Admin/Components/Login/Login"));
const Dashboard = React.lazy(() => import("./Admin/Components/Dashboard/Dashboard"));
const Dashboard_Home = React.lazy(() => import("./Admin/Components/Dashboard_Home/Dashboard_Home"));
const Category = React.lazy(() => import("./Admin/Components/Category/Category"));
const Product = React.lazy(() => import("./Admin/Components/Product/Product"));
const Add_blog = React.lazy(() => import("./Admin/Components/Add_blog/Add_blog"));
const Add_Our_Culture = React.lazy(() => import("./Admin/Components/Add_Our_Culture/Add_Our_Culture"));
const AddAboutus = React.lazy(() => import("./Admin/Components/AddAboutus/AddAboutus"));
const Payment = React.lazy(() => import("./components/Payment/Payment"));
const ChatBoxQus = React.lazy(() => import("./Admin/Components/ChatBoxQus/ChatBoxQus"));
const OurShopLocations = React.lazy(() => import("./Admin/Components/OurShopLocations/OurShopLocations"));
const Franchise = React.lazy(() => import("./components/Franchise/Home_Contactus"));
const ContactDetails = React.lazy(() => import("./Admin/Components/ContactDetails/ContactDetails"));
const SliderVideo = React.lazy(() => import("./Admin/Components/SliderVideo/SliderVideo"));
const ForgotPassword = React.lazy(() => import("./Admin/Components/FotgotPassword/FotgotPassword"));

const App = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
  }, []);

  const adminPaths = [
    "/dashboard",
    "/addcategory",
    "/product",
    "/addblog",
    "/Add_Our_Culture",
    "/login",
    "/addaboutus",
    "/chatboxque",
    "/ourshoplocation",
    "/ContactDetails",
    "/SliderVideo",
    "/ForgotPassword",
  ];
  const hideNavbarAndFooter = adminPaths.some((path) => location.pathname.startsWith(path));

  const routes = useMemo(
    () => (
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/aboutus" element={<About_Us />} />
          <Route path="/location" element={<ShopeLocation />} />
          <Route path="/gallery" element={<Home_Gallery />} />
          <Route path="/blog" element={<Home_Blog />} />
          <Route path="/category" element={<CategoryDetails />} />
          <Route path="/Payment" element={<Payment />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/ForgotPassword"
            element={isAuthenticated ? <ForgotPassword /> : <Navigate to="/login" />}
          />
          <Route
            path="/dashboardhome"
            element={isAuthenticated ? <Dashboard_Home /> : <Navigate to="/login" />}
          />
          <Route
            path="/addcategory"
            element={isAuthenticated ? <Category /> : <Navigate to="/login" />}
          />
          <Route
            path="/product"
            element={isAuthenticated ? <Product /> : <Navigate to="/login" />}
          />
          <Route
            path="/addblog"
            element={isAuthenticated ? <Add_blog /> : <Navigate to="/login" />}
          />
          <Route
            path="/addaboutus"
            element={isAuthenticated ? <AddAboutus /> : <Navigate to="/login" />}
          />
          <Route
            path="/Add_Our_Culture"
            element={isAuthenticated ? <Add_Our_Culture /> : <Navigate to="/login" />}
          />
          <Route
            path="/ContactDetails"
            element={isAuthenticated ? <ContactDetails /> : <Navigate to="/login" />}
          />
          <Route
            path="/chatboxque"
            element={isAuthenticated ? <ChatBoxQus /> : <Navigate to="/login" />}
          />
          <Route
            path="/ourshoplocation"
            element={isAuthenticated ? <OurShopLocations /> : <Navigate to="/login" />}
          />
          <Route
            path="/SliderVideo"
            element={isAuthenticated ? <SliderVideo /> : <Navigate to="/login" />}
          />
        </Routes>
      </Suspense>
    ),
    [isAuthenticated]
  );

  return (
    <>
      {!hideNavbarAndFooter && <Suspense fallback={<div>Loading Navbar...</div>}><Navbar /></Suspense>}
      {routes}
      {!hideNavbarAndFooter && <Suspense fallback={<div>Loading Chatbox...</div>}><Chatbox /></Suspense>}
    </>
  );
};

const AppWrapper = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWrapper;
